import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import ContentPage from '@frontend/containers/ContentPage';
import * as React from 'react';
import { reportWebVitals as ReportWebVitals } from '../hooks/ga';

var IndexPage = function IndexPage() {
  return _jsx(ContentPage, {});
};

export var __N_SSG = true;
export default IndexPage;
export function reportWebVitals(metric) {
  ReportWebVitals(metric);
}